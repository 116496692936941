export default [
  {
    path: '/courses/:C_id',
    name: 'courses',
    component: () => import('@/views/engineers-courses/Courses.vue'),
    meta: {
      title: 'Engineers Courses ',
      requiresAuth: false,
      action: 'browes',
      resource: 'Courses',
      pageTitle: 'Engineers Courses',
      breadcrumb: [
        {
          text: 'Engineers Courses',
          to: '/engineers-courses',
        },
        {
          text: 'Courses',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/courses/:C_id/add',
    name: 'add-courses',
    component: () => import(
      '@/views/engineers-courses/control-engineers-courses/AddEditEngineersCourses.vue'
    ),
    meta: {
      title: 'Engineers Courses ',
      requiresAuth: false,
      action: 'browes',
      resource: 'Courses',
      pageTitle: 'Engineers Courses',
      breadcrumb: [
        {
          text: 'Engineers Courses',
          to: '/engineers-courses',
        },
        {
          text: 'Courses List',
          active: true,
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/courses/:C_id/edit/:id/:name',
    name: 'edit-courses',
    component: () => import(
      '@/views/engineers-courses/control-engineers-courses/AddEditEngineersCourses.vue'
    ),
    meta: {
      title: 'Engineers Courses ',
      requiresAuth: false,
      action: 'browes',
      resource: 'Courses',
      pageTitle: 'Engineers Courses',
      breadcrumb: [
        {
          text: 'Engineers Courses',
          to: '/engineers-courses',
        },
        {
          text: 'Courses List',
          active: true,
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
]
