/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
import axios from 'axios'
// import axiosInstances from '@/libs/axios-instances'
import router from '@/router'
// import decryptData from './decryptData'

export default {
  namespaced: true,
  state: {
    token: '',
    refresh_token: '',
    access_token: '',
    role: '',
    access_role: '',
    loggedInUser: {},
    isAuthenticated: false,
    loader: false,
    loginError: null,
    userInfo: {},
  },
  getters: {
    loggedInUser: state => state.loggedInUser,
    isAuthenticated: state => state.isAuthenticated,
    accessToken: state => state.access_token,
    username: state => state.access.name,
    email: state => state.access.email,
    accessRole: state => state.access_role,
    userInfo: state => state.userInfo,
  },
  mutations: {
    SET_TOKEN(state, token) {
      localStorage.setItem('accessToken', token)
      state.token = token
    },

    SET_Role(state, role) {
      localStorage.setItem('accessRole', role)
      state.role = role
    },

    SET_LOGED_IN_USER(state, user) {
      state.loggedInUser = user
      state.isAuthenticated = true
    },
    CLEAR_USER_DATA(state) {
      state.refresh_token = ''
      state.access_token = ''
      state.loggedInUser = {}
      state.isAuthenticated = false
    },
    SET_LOADER(state, loader) {
      state.loader = loader
    },
    STOP_LOGIN(state, errorLogin) {
      state.loader = false
      state.loginError = errorLogin
    },
    SET_USER_INFO(state, info) {
      state.userInfo = info
      localStorage.setItem('user_info', info)
    },
  },
  actions: {
    async login({ commit, getters }, userCredentials) {
      const loginEndPoint = 'login'
      commit('SET_LOADER', true)
      try {
        await axios
          .post(loginEndPoint, userCredentials)
          .then(res => {
            if (res.status === 200) {
              // const userDataDecrypted = decryptData(res.data.payload)
              // console.log(userDataDecrypted);

              localStorage.setItem('accessToken', res.data.data.token)
              localStorage.setItem('user_info', res.data.data.admin)
              localStorage.setItem('user_role', res.data.data.admin.role)
              localStorage.setItem('name', res.data.data.admin.name)
              localStorage.setItem('email', res.data.data.admin.email)
              commit('SET_USER_INFO', res.data.data.admin)
              commit('SET_TOKEN', res.data.data.token)
              commit('SET_Role', res.data.data.admin.role)
              router.push('/dashboard')
              // window.location.href = '/'
            }
          })
          .finally(() => {
            commit('SET_LOADER', false)
          })
      } catch (e) {
        if (e) {
          commit('STOP_LOGIN', 'Please enter a valid username and password')
        }
      }
    },
  },
}
