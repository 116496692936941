export default [
  {
    path: '/facility',
    name: 'facility',
    component: () => import('@/views/Facility/Facility.vue'),
    meta: {
      title: 'Facility ',
      requiresAuth: false,
      action: 'browes',
      resource: 'Facility',
      pageTitle: 'Facility',
      breadcrumb: [
        {
          text: 'Facility',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/facility/:id',
    name: 'show-facility',
    component: () => import('@/views/Facility/control-facility/ShowFacility.vue'),
    meta: {
      title: 'Facility Info',
      requiresAuth: false,
      action: 'browes',
      resource: 'Facility Info',
      pageTitle: 'Facility Info',
      breadcrumb: [
        {
          text: 'Facility Info',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/Facility/add',
  //   name: 'add-city',
  //   component: () => import('@/views/cities/control-city/AddEditCity.vue'),
  //   meta: {
  //     title: 'Add City ',
  //     requiresAuth: false,
  //     action: 'create',
  //     resource: 'city',
  //     pageTitle: 'Cities',
  //     breadcrumb: [
  //       {
  //         text: 'Cities',
  //         to: '/cities',
  //       },
  //       {
  //         text: 'Add',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/cities/edit/:id',
  //   name: 'edit-city',
  //   component: () => import('@/views/cities/control-city/AddEditCity.vue'),
  //   meta: {
  //     title: 'Edit City ',
  //     requiresAuth: false,
  //     pageTitle: 'Cities',
  //     breadcrumb: [
  //       {
  //         text: 'Cities',
  //         to: '/cities',
  //       },
  //       {
  //         text: 'Edit',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]
