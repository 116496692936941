export default [
  {
    path: '/office/:id',
    name: 'set-office',
    component: () => import('@/views/requests/control-requests/SetOffice.vue'),
    meta: {
      title: 'Add office ',
      requiresAuth: false,
      action: 'create',
      resource: 'office',
      pageTitle: 'office',
      breadcrumb: [
        {
          text: 'office',
          to: '/office',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
]
