export default [
  {
    path: '/park-entries',
    name: 'Park Entries',
    component: () => import('@/views/accounting/park-entries/ParkEntries.vue'),
    meta: {
      title: 'Park Entries',
      requiresAuth: false,
      action: 'browes',
      resource: 'Park Entries',
      pageTitle: 'Park Entries',
      breadcrumb: [
        {
          text: 'Park Entries',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/park-entries/add',
    name: 'add-park-entries',
    component: () => import(
      '@/views/accounting/park-entries/control-entries/AddEditEntries.vue'
    ),
    meta: {
      title: 'Add Park Entries ',
      requiresAuth: false,
      action: 'create',
      resource: 'Park Entries',
      pageTitle: 'Park Entries',
      breadcrumb: [
        {
          text: 'Park Entries',
          to: '/park-entries',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/park-entries/edit/:id/',
    name: 'edit-park-entries',
    component: () => import(
      '@/views/accounting/park-entries/control-entries/AddEditEntries.vue'
    ),
    meta: {
      title: 'Edit Park Entries ',
      requiresAuth: false,
      pageTitle: 'Park Entries',
      breadcrumb: [
        {
          text: 'Park Entries',
          to: '/park-entries',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
]
