export default [
  {
    path: '/accounting',
    name: 'accounting',
    component: () => import('@/views/accounting/Accounting.vue'),
    meta: {
      title: 'Accounting',
      requiresAuth: false,
      action: 'browes',
      resource: 'Accounting',
      pageTitle: 'Accounting',
      breadcrumb: [
        {
          text: 'Accounting',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/post-entry/:id',
    name: 'PostEntry',
    component: () => import('@/views/accounting/GetById.vue'),
  },
  {
    path: '/accounting/add',
    name: 'add-account',
    component: () => import('@/views/accounting/control-account/AddEditAccount.vue'),
    meta: {
      title: 'Add Account ',
      requiresAuth: false,
      action: 'create',
      resource: 'account',
      pageTitle: 'Account',
      breadcrumb: [
        {
          text: 'Account',
          to: '/accounting',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/accounting/edit/:id/:name',
    name: 'edit-account',
    component: () => import('@/views/accounting/control-account/AddEditAccount.vue'),
    meta: {
      title: 'Edit Account ',
      requiresAuth: false,
      pageTitle: 'Account',
      breadcrumb: [
        {
          text: 'Account',
          to: '/account',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
]
