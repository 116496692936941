export default [
  {
    path: '/devices',
    name: 'devices',
    component: () => import('@/views/devices/Devices.vue'),
    meta: {
      title: 'Devices',
      requiresAuth: false,
      action: 'browes',
      resource: 'Devices',
      pageTitle: 'Devices',
      breadcrumb: [
        {
          text: 'Devices',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/device/add',
    name: 'add-device',
    component: () => import('@/views/devices/control-devices/AddEditDevices.vue'),
    meta: {
      title: 'Add device ',
      requiresAuth: false,
      action: 'create',
      resource: 'device',
      pageTitle: 'device',
      breadcrumb: [
        {
          text: 'device',
          to: '/device',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/devices/edit/:id',
    name: 'edit-devices',
    component: () => import('@/views/devices/control-devices/AddEditDevices.vue'),
    meta: {
      title: 'Edit Devices ',
      requiresAuth: false,
      pageTitle: 'devices',
      breadcrumb: [
        {
          text: 'Devices',
          to: '/devices',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
]
