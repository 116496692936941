export default [
  {
    path: '/analysis',
    name: 'analysis',
    component: () => import('@/views/analysis/Analysis.vue'),
    meta: {
      title: 'Analysis - System Gulftic',
      requiresAuth: false,
    },
  },
]
