export default [
  {
    path: '/employees',
    name: 'employees',
    component: () => import('@/views/employees/Employees.vue'),
    meta: {
      title: 'Employees - DealMart',
      requiresAuth: true,
      pageTitle: ' Employees',
      action: 'browes',
      breadcrumb: [
        {
          text: 'Employees',
          active: true,
        },
      ],
    },
  },

  {
    path: '/employees/approval/:id',
    name: 'approval-employee',
    component: () => import('@/views/employees/control-employees/NeedApproveEmployees.vue'),
    meta: {
      title: 'Approval Employee',
      requiresAuth: false,
      action: 'create',
      pageTitle: 'New Employees',
      breadcrumb: [
        {
          text: 'Employees',
          to: '/employees',
        },
        {
          text: 'Approval',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/employees/edit/:id',
  //   name: 'edit-employee',
  //   component: () => import('@/views/employees/control-employees/NeedApproveEmployees.vue'),
  //   meta: {
  //     title: 'Edit Employees',
  //     requiresAuth: false,
  //     action: 'update',
  //     pageTitle: ' Employees',
  //     breadcrumb: [
  //       {
  //         text: 'Employees',
  //         to: '/employees',
  //       },
  //       {
  //         text: 'Edit',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]
