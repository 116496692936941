export default [
  {
    path: '/task-management-assigned',
    name: 'Task Management Assigned',
    component: () => import('@/views/task-management/TaskManagementAssigned.vue'),
    meta: {
      title: 'Assigned Task Management',
      requiresAuth: false,
      action: 'browes',
      resource: 'task-management-assigned',
      pageTitle: 'Assigned Task Management',
      breadcrumb: [
        {
          text: 'Assigned Task Management',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/task-management-created',
    name: 'Task Management Created',
    component: () => import('@/views/task-management/TaskManagementCreated.vue'),
    meta: {
      title: 'Created Task Management',
      requiresAuth: false,
      action: 'browes',
      resource: 'task-management-created',
      pageTitle: 'Created Task Management',
      breadcrumb: [
        {
          text: 'Created Task Management',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/task-management/add',
    name: 'create-task',
    component: () => import('@/views/task-management/control-task-management/AddEditTask.vue'),
    meta: {
      title: 'Add Task Management ',
      requiresAuth: false,
      action: 'create',
      resource: 'Task Management',
      pageTitle: 'Task Management',
      breadcrumb: [
        {
          text: 'Task Management',
          to: '/task-management-created',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/task-management/edit/:id',
    name: 'edit-task',
    component: () => import('@/views/task-management/control-task-management/AddEditTask.vue'),
    meta: {
      title: 'Edit Task Management ',
      requiresAuth: false,
      action: 'edit',
      resource: 'Task Management',
      pageTitle: 'Task Management',
      breadcrumb: [
        {
          text: 'Task Management',
          to: '/task-management-created',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/task-management/info/:id',
    name: 'view-task',
    component: () => import(
      '@/views/task-management/control-task-management/ViewTaskManagement.vue'
    ),
    meta: {
      title: 'View Task Management ',
      requiresAuth: false,
      action: 'view',
      resource: 'Task Management',
      pageTitle: 'Task Management',
      breadcrumb: [
        {
          text: 'Task Management',
          to: '/task-management-created',
        },
        {
          text: 'View',
          active: true,
        },
      ],
    },
  },
]
