export default [
  {
    path: '/source-account',
    name: 'source-account',
    component: () => import('@/views/source-account/SourceAccount.vue'),
    meta: {
      title: 'Source Account ',
      requiresAuth: false,
      action: 'browes',
      resource: 'source-account',
      pageTitle: 'source-account',
      breadcrumb: [
        {
          text: 'source-account',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/source-account/add',
    name: 'add-source-account',
    component: () => import(
      '@/views/source-account/control-source-account/AddEditSourceAccount.vue'
    ),
    meta: {
      title: 'Add Source Account ',
      requiresAuth: false,
      action: 'create',
      resource: 'Source Account',
      pageTitle: 'Source Account',
      breadcrumb: [
        {
          text: 'Source Account',
          to: '/source-account',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/source-account/edit/:id',
    name: 'edit-source-account',
    component: () => import(
      '@/views/source-account/control-source-account/AddEditSourceAccount.vue'
    ),
    meta: {
      title: 'Edit Source Account  ',
      requiresAuth: false,
      pageTitle: 'Source Account',
      breadcrumb: [
        {
          text: 'Source Account',
          to: '/source-account',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
]
