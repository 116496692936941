export default [
  {
    path: '/report',
    name: 'report',
    component: () => import('@/views/report/Report.vue'),
    meta: {
      title: 'report ',
      requiresAuth: false,
      action: 'browes',
      resource: 'report',
      pageTitle: 'report',
      breadcrumb: [
        {
          text: 'report',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report/add',
    name: 'add-report',
    component: () => import('@/views/report/control-report/AddEditReport.vue'),
    meta: {
      title: 'Add report ',
      requiresAuth: false,
      action: 'create',
      resource: 'report',
      pageTitle: 'report',
      breadcrumb: [
        {
          text: 'report',
          to: '/report',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report/edit/:id',
    name: 'edit-report',
    component: () => import('@/views/report/control-report/AddEditReport.vue'),
    meta: {
      title: 'Edit Report ',
      requiresAuth: false,
      pageTitle: 'report',
      breadcrumb: [
        {
          text: 'report',
          to: '/report',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
]
